import {User} from '@hconnect/apiclient/src'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {TextButton} from '@hconnect/uikit'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {Box, Card, CardContent, Grid, IconButton} from '@mui/material'
import classNames from 'classnames'
import queryString from 'query-string'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector, useDispatch} from 'react-redux'
import {makeStyles} from 'tss-react/mui'
import {v4 as uuidV4} from 'uuid'

import {ReactComponent as PdfIcon} from '../../../Assets/pdf.svg'
import {FieldValue} from '../../../Molecules/FieldDetails'
import {selectSelectedCustomer} from '../../../Organisms/Customers'
import {Customer} from '../../../Organisms/Customers/Customers.types'
import {
  Entity,
  EntityPage,
  getDocumentFileName,
  downloadDocument as downloadDocumentAbstract
} from '../../../Organisms/Documents'
import {AppState} from '../../../Root.store'
import {LinkMedia, LinkMediaType} from '../../Finance/Invoices/Delivery.types'
import {useIconStyles} from '../../Finance/Invoices/InvoiceDetail/components'

import {Link, MaterialCertification} from './types'
import {BusinessLineType} from '../../../common/types'

const useStyles = makeStyles()(() => ({
  card: {
    width: '100%',
    marginBottom: 8,
    paddingTop: 2,
    paddingBottom: 2,
    background: 'rgba(1, 106, 212, 0.04)',
    border: '1px solid #016AD4'
  },
  linearOverlay: {
    maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)'
  },
  itemKey: {
    textTransform: 'uppercase',
    color: '#628390',
    fontWeight: 700,
    fontSize: '12px'
  },
  itemValue: {
    marginLeft: '4px',
    fontSize: '16px'
  }
}))

interface CertificateLineItemCardProps {
  viewAll?: boolean
  date: string
  plantName: string
  links: Link[]
  materialDescription: string
}

export const CertificateLineItemCard: React.FC<CertificateLineItemCardProps> = ({
  date,
  plantName,
  materialDescription,
  links,
  viewAll
}) => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const classesIcon = useIconStyles()

  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const {customerId} = queryString.parse(window.location.search)
  const customer = useSelector<AppState, Customer | undefined>((state: AppState) =>
    selectSelectedCustomer(state, customerId)
  )
  const dispatch = useDispatch()

  const downloadDocument = (jobId: string, documentId: string, fileName: string) =>
    dispatch(downloadDocumentAbstract(jobId, documentId, fileName))

  const certificatePdf = links && links.length ? links[0] : undefined
  const documentId = certificatePdf ? certificatePdf.href.split('/')[1] : undefined
  const fileName = certificatePdf
    ? getDocumentFileName(
        EntityPage.testsCertificates,
        Entity.millTestReports,
        certificatePdf?.media as LinkMediaType,
        t,
        {plantName, materialDescription, date}
      )
    : `${Entity.millTestReports}.${LinkMedia.Pdf}`

  const pdfBtnClickHandler = () => {
    const jobId: string = uuidV4()
    trackEvent('hubDownloadSingle', {
      product: 'hub',
      jobId: jobId,
      downloadedItem: 'certificate',
      linkToDownloadedItem: certificatePdf?.href,
      customerId: customerId,
      customerName: customer?.customerName,
      entryPoint: 'Certificate List',
      issuedDate: date,
      plant: plantName,
      material: materialDescription,
      userId: user?.user_id,
      userMail: user?.eMail,
      userCountry: user?.country,
      userRoles: user?.hasRoles,
      userIsInternal: user?.isInternal,
      downloadedItemBusinessLine: BusinessLineType.CEM
    })
    documentId && downloadDocument(jobId, documentId, fileName)
  }

  return (
    <Card
      variant="outlined"
      className={classNames([
        classes.card,
        {
          [classes.linearOverlay]: !viewAll
        }
      ])}
    >
      <CardContent style={{paddingBottom: '8px'}}>
        <Grid container>
          <Grid item xs={6}>
            <Box display="flex">
              <div className={classes.itemKey}>{t('certificate.columns.certificationDate')}</div>
              <div className={classes.itemValue} data-test-id="certification-date-field">
                {date}
              </div>
            </Box>
            <Box display="flex">
              <div className={classes.itemKey}>{t('certificate.columns.plant')}</div>
              <div className={classes.itemValue} data-test-id="ticket-material-field">
                {materialDescription}
              </div>
            </Box>
          </Grid>
          <Grid item xs={6} justifyContent="end" alignItems="center" display="flex">
            <IconButton
              className={classesIcon.classes.icon}
              size="large"
              onClick={pdfBtnClickHandler}
              data-test-id="pdf-download-button"
            >
              <PdfIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

interface CardListProps {
  materialCertificationList: MaterialCertification[]
  viewAll?: boolean
  materialDescription: string
  plantName: string
}
export const CertificateLineItemCardList: React.FC<CardListProps> = ({
  materialCertificationList,
  materialDescription,
  plantName,
  viewAll
}) => {
  return (
    <Box data-test-id="certificate-list" my={1.75}>
      {materialCertificationList.map((certificate) => (
        <CertificateLineItemCard
          key={certificate.materialCertificateId}
          date={certificate.certificationDate as string}
          materialDescription={materialDescription}
          plantName={plantName}
          links={certificate.links}
          viewAll={viewAll}
        />
      ))}
    </Box>
  )
}

interface ListProps {
  materialCertificationList: MaterialCertification[]
  materialDescription: string
  plantName: string
}

export const MaterialCertificateLineItemList: React.FC<ListProps> = ({
  materialCertificationList,
  materialDescription,
  plantName
}) => {
  const {t} = useTranslation()
  const [viewAll, setViewAll] = useState<boolean>(materialCertificationList?.length === 1)
  return (
    <Box display="flex" flexDirection="column" pt={1}>
      <Grid container>
        <Grid item xs={6}>
          <FieldValue
            dataTestId="material-certificate-items"
            label={t('certificate.materialCertificateListTitle')}
            value={t('certificate.certificateItemsListed', {
              numberOfItems: materialCertificationList.length
            })}
          />
        </Grid>
        <Grid item xs={6}>
          {materialCertificationList.length > 1 && (
            <TextButton
              endIcon={<ArrowDropDownIcon />}
              data-test-id="material-certificate-view-all"
              color="secondary"
              variant="text"
              onClick={() => setViewAll(!viewAll)}
            >
              {t('certificate.certificateItemsListed', {
                numberOfItems: materialCertificationList.length
              })}
            </TextButton>
          )}
        </Grid>
      </Grid>
      <CertificateLineItemCardList
        materialCertificationList={
          viewAll ? materialCertificationList : materialCertificationList.slice(0, 1)
        }
        viewAll={viewAll}
        materialDescription={materialDescription}
        plantName={plantName}
      />
    </Box>
  )
}
